<template>
    <div id="order-details" >
        <v-container>
            <v-row>
                <v-col cols="12">
                    <h1>
                        Detalles del pedido
                    </h1>
                </v-col>
            </v-row>
            <v-row v-if="order">
                <v-col cols="12" md="8">
                    <h2 class="pb-5"> Status: <span class="header-noBold">{{order.status}}</span> </h2>
                    <p v-if="order.statusDelayText">
                        {{order.statusDelayText}}
                    </p>
                    <h2>Datos de contacto</h2>
                    <v-row class="my-1">
                        <v-col cols="12" md="6" class="pb-3">
                            <v-text-field
                                v-model="order.firstName"
                                label="Nombre"
                                color="#000000"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-3">
                            <v-text-field
                                v-model="order.lastName"
                                label="Apellido"
                                color="#000000"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="my-1">
                        <v-col cols="12" md="6" class="pb-3">
                            <v-text-field
                                v-model="order.email"
                                label="E-mail"
                                color="#000000"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-3">
                            <v-text-field
                                :value="hideNumber(order.phoneNumber)"
                                label="Teléfono"
                                color="#000000"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <h2>Datos de entrega</h2>
                    <v-row class="my-1">
                        <v-col cols="12" md="6" class="pb-3">
                            <v-text-field
                                v-model="order.city"
                                label="Ciudad"
                                color="#000000"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-3">
                            <v-text-field
                                v-model="order.state"
                                label="Estado"
                                color="#000000"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                
                <v-col cols="12" md="4">
                <section>
                    <div class="summary-boxes">
                        <h2>Resumen</h2>
                        <div>
                            <p>
                                Total de Artículos:
                            </p>
                            <p>
                                 ${{numberWithCommas(order.subTotalPrice)}}
                            </p>
                        </div>
                        <div>
                            <p>
                                Envio:
                            </p>
                            <p>
                                ${{numberWithCommas(order.shippingPrice)}}
                            </p>
                        </div>
                        <hr />
                        <div>
                            <p>
                                Total:
                            </p>
                            <p>
                                ${{numberWithCommas(order.totalOrderPrice)}}
                            </p>
                        </div>

                    </div>
                    <div class="summary-boxes">
                        <h2>Artículos</h2>
                         <v-card elevation="0" v-for="(product, index) in order.details" :key="'item-'+index" class="mb-5 article-summary">
                            <v-row>
                                <v-col cols="auto" class="pa-0">
                                    <v-img  width="110"  aspect-ratio="1" :alt=product.productName :src="'https://rawkv3.actstudio.xyz/storage/uploads'+product.productImage"></v-img>
                                </v-col>
                                <v-col class="cart-mini-body-info">
                                    <p>
                                        {{product.subCategory}}
                                           
                                    </p>
                                    <p>
                                        {{product.productName}}
                                    </p>

                                    <p>
                                        {{product.size}}
                                        <span>-</span>
                                        ${{product.unitPrice}}
                                    </p>
                                </v-col>
                                <v-col cols="auto" class="text-center " >
                                    <p>Cantidad</p>
                                    <p>{{product.quantity}}</p>
                                </v-col>
                            </v-row>
                        </v-card>
                    </div>
                </section>
            </v-col>
            </v-row>
        </v-container>
    </div>  
</template>

<script>
export default {
    data() {
        return {
            order: {
                _id: '',
                email: '',
                details: []
            },
        }
    },
    beforeMount: function() {
        fetch('https://rawkv3.actstudio.xyz/api/content/items/Orders?populate=2&filter={_id:"'+this.$route.params.orderId +'"}', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },

        })
        .then(res=> res.json())
        .then(res => this.order = res[0])
        .catch((error) => {
            console.error('Error:', error);
        });
    },
    methods: {
        numberWithCommas: function(x) {
            if(x)
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        hideNumber: function(num) {
           
            if(num) {
                let phone = num.split('');
                let length = phone.length;
                let visNumber = 3;
                let hideNumber = [];
                phone.forEach((elem, index) => {

                    if(index + visNumber == length){
                        hideNumber.push(elem);
                        visNumber --;
                    
                    }else {
                        hideNumber.push('*');
                    }
                    
                });
                return hideNumber.join('');
            }
        }
    }

}
</script>
